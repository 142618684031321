import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import Nav from "../Nav"
import Header from "../Header"
import Footer from "../Footer"
// Master CSS Import
import "../../styles/styles.css"
// Styled Components Theme/Global
import { GlobalStyles, Theme } from "../../styles"

const Layout = ({ children, fluid, alt }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <Nav />
    {fluid && <Header fluid={fluid} alt={alt} />}
    <main>{children}</main>
    <Footer />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
