import { navigate } from "gatsby"
import Axios from "axios"

import { SERVER_URL } from "@config"
import { SetToken, ClearToken, GetToken } from "@utils"
import {
  LOADING,
  AUTH_USER,
  AUTH_ERROR,
  UNAUTH_USER,
  REGISTER_TOKEN,
  CLIENT_CHECKIN_SUCCESS,
  CLIENT_CHECKIN_ERROR,
  CLEAR_ERROR_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
} from "../types"
/** ------------------------------------------------------------------
 *! Set Register Token [temporary] and Email
 */
export const SetTempRegister = ({ token, email }) => {
  return async dispatch => {
    dispatch({ type: REGISTER_TOKEN, payload: { token, email } })
  }
}
/** ------------------------------------------------------------------
 *! Clear Errors/Successes
 */
export const ClearErrorSuccess = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_ERROR_SUCCESS })
  }
}
/** ------------------------------------------------------------------
 *! Submit Client Checkin
 */
export const SubmitClientCheckin = ({ formData }) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    const token = GetToken()
    if (token) {
      try {
        const { data } = await Axios.post(
          `${SERVER_URL}/client-checkin`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: token,
            },
          }
        )
        dispatch({ type: CLIENT_CHECKIN_SUCCESS, payload: data.message })
      } catch (error) {
        dispatch({
          type: CLIENT_CHECKIN_ERROR,
          payload:
            error?.response?.data?.message || "Client Checkin Error Occured.",
        })
      }
    } else {
      ReduxLogout()
    }
  }
}
/** ------------------------------------------------------------------
 *! Create Login
 */
export const ReduxCreateLogin = ({ email, password, token }) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await Axios.post(`${SERVER_URL}/create-login`, {
        token,
        email,
        password,
      })
      SetToken(data.token)
      dispatch({ type: AUTH_USER, payload: data.user })
      navigate("/app/profile")
    } catch (error) {
      ClearToken()
      dispatch({
        type: AUTH_ERROR,
        payload:
          error?.response?.data?.message || "Unknown Auth Error Occured.",
      })
    }
  }
}
/** ------------------------------------------------------------------
 *! Login
 */
export const ReduxLogin = ({ email, password }) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await Axios.post(`${SERVER_URL}/login`, {
        email,
        password,
      })
      console.log(data)
      SetToken(data.token)
      dispatch({ type: AUTH_USER, payload: data.user })
      navigate("/app/profile")
    } catch (error) {
      ClearToken()
      dispatch({
        type: AUTH_ERROR,
        payload:
          error?.response?.data?.message || "Unknown Auth Error Occured.",
      })
    }
  }
}
/** ------------------------------------------------------------------
 *! Forgot Password
 */
export const ReduxForgotPassword = ({ email }) => {
  return async dispatch => {
    dispatch({ type: LOADING })
    try {
      const { data } = await Axios.post(`${SERVER_URL}/reset-password`, {
        email,
      })
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message })
    } catch (error) {
      ClearToken()
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload:
          error?.response?.data?.message ||
          "Unknown Password Reset Error Occured.",
      })
    }
  }
}
/** ------------------------------------------------------------------
 *! Logout
 */
export const ReduxLogout = () => {
  return async dispatch => {
    dispatch({ type: LOADING })
    ClearToken()
    dispatch({ type: UNAUTH_USER })
    navigate("/app/login")
  }
}
