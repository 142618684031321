const Theme = {
  primary: "#d41f1f",
  green: "#62c81e",
  black: "#000000",
  white: "#ffffff",
  grey: "#1b1b1b",
  lightGrey: "#484848",
  bronze: "#CD7F32",
  silver: "#C0C0C0",
  gold: "#FFD700",
  platinum: "#E5E4E2",
  robotoFont: `'Roboto', sans-serif;`,
  bebasFont: `'Bebas Neue', cursive;`,
  // -----------------------------------------------------------------
  mobileBreakPoint: {
    SP: `380px`,
    M: `420px`,
    L: `560px`,
  },
  // Responsive Breakpoints
  breakPoint: {
    // Phone's portrait mode usually ranges from 360px - 640px
    SM: `640px`,
    MD: `768px`,
    LG: `1024px`,
    XL: `1280px`,
  },
}

export default Theme
