import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"

import { Facebook, Twitter, Instagram, Youtube } from "../Fontawesome"

import { SOCIALS } from "../../config"

const Footer = Styled.footer`
    background-color: ${({ theme }) => theme.primary};
    position: relative;
    z-index: 1;
    margin-top: 100px;
    padding: 20px;
    &:before {
        background: inherit;
        content: '';
        display: block;
        z-index: -1;
        height: 100px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: skewY(-3deg);
        transform-origin: 0% 0;
    }
    &:after {
        background: inherit;
        content: '';
        display: block;
        z-index: -1;
        height: 100px;
        left: 0;
        position: absolute;
        right: 0;
        bottom: 0;
        transform: skewY(0deg);
        transform-origin: 100%;
    }
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
    "cards y3t social";

    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
      "social"
      "cards"
      "y3t";
    }
`
const Cards = Styled(Img)`
    grid-area: cards;
    justify-self: start;
    align-self: end;
    width: 200px;
    margin-left: 5px;
    margin-bottom: 1rem;
    @media screen and (max-width: 600px) {
      place-self: center;
    }
`
const Y3T = Styled.div`
    grid-area: y3t;
    place-self: center;
    p {
      text-align: center;
      font-family: ${({ theme }) => theme.robotoFont};
    }
`
const Social = Styled.ul`
    grid-area: social;
    place-self: end;
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 600px) {
      place-self: center;
    }
`

const MyFooter = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      cards: allFile(filter: { name: { eq: "cards_362x45" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 362) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const cards = data.cards.edges[0].node.childImageSharp.fluid
  return (
    <Footer>
      <Cards
        fluid={cards}
        alt="Accepted Cards"
        imgStyle={{ objectFit: "contain" }}
      />
      <Y3T>
        <p>Y3T Since 1991</p>
        <p>
          &copy; {new Date().getFullYear()} Neil Hill Coaching Ltd. All Rights
          Reserved.
        </p>
      </Y3T>
      <Social>
        <li>
          <Facebook href={SOCIALS.FACEBOOK} title="Facebook" />
        </li>
        <li>
          <Twitter href={SOCIALS.TWITTER} title="Twitter" />
        </li>
        <li>
          <Instagram href={SOCIALS.INSTAGRAM} title="Instagram" />
        </li>
        <li>
          <Youtube href={SOCIALS.YOUTUBE} title="Youtube" />
        </li>
      </Social>
    </Footer>
  )
}

export default MyFooter
