import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import Styled from "styled-components"

const Header = Styled.header`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
`
const BgImage = Styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  & > img {
    object-fit: cover !important;
    object-position: 0% 0% !important;
    font-family: 'object-fit: cover !important; object-position: 0% 0% !important;';
  }
`

const MyHeader = ({ fluid, alt }) => {
  if (fluid) {
    return (
      <Header>
        <BgImage fluid={fluid} alt={alt} />
      </Header>
    )
  }
}

MyHeader.propTypes = {
  alt: PropTypes.string.isRequired,
  fluid: PropTypes.object.isRequired,
  height: PropTypes.number,
  heightMobile: PropTypes.number,
  children: PropTypes.node,
}

export default MyHeader
