import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    *, *::after, *::before {
        box-sizing: border-box;
    }
    body {
        background: ${({ theme }) => theme.black};
        color: ${({ theme }) => theme.white};
        text-rendering: optimizeLegibility;
    }

    button:focus {
        outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        background-color: #1b1b1b;
        color: #d41f1f !important;
        border: 1px solid #484848;
        -webkit-text-fill-color: #d41f1f;
        transition: background-color 5000s ease-in-out 0s;
    }

    select {
        /* Appearance: none will hide the default arrow */
        appearance: none;
        /* Best to include the browser prefix for cross-browser compatibility */
        -webkit-appearance: none;
        -moz-appearance: none;
    }
  `
export default GlobalStyles
