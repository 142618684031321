import * as React from "react"
import { motion } from "framer-motion"
import Styled from "styled-components"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import Item from "./Item"
import { ROUTES } from "../../config"

const UL = Styled(motion.ul)`
    padding: 25px;
    position: absolute;
    top: 70px;
    width: 100%;
    li {
        list-style: none;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const NavItems = ({ open, authenticated, ReduxLogout }) => (
  <UL variants={variants}>
    {ROUTES.map(route => (
      <Item
        open={open}
        key={`menu-${route.name}`}
        path={route.path}
        name={route.name}
      />
    ))}
    {authenticated ? (
      <>
        <Item open={open} path="/app/profile" name="Profile" />
        <Item open={open} onClick={() => ReduxLogout()} name="Logout" />
      </>
    ) : (
      <Item open={open} path="/app/login" name="Login" />
    )}
  </UL>
)

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
  }
}
export default connect(mapStateToProps, actions)(NavItems)
