import React from "react"
import Styled from "styled-components"

const A = Styled.a`
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    path {
      fill: ${({ theme }) => theme.black};
    }
    &:hover {
        text-decoration: none;
        svg {
          path {
            fill: ${({ theme }) => theme.white};
          }
        }
    }
`
const Svg = Styled.svg``

const Facebook = ({
  width,
  color,
  onClick,
  title,
  href,
  target = "_blank",
}) => (
  <A href={href} rel="noopener noreferrer" target={target}>
    <Svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fab"
      data-icon="facebook-f"
      role="img"
      viewBox="0 0 320 512"
      width={width ? width : 20}
      onClick={onClick}
    >
      {title && <title>{title}</title>}
      <path
        fill={color ? color : "currentColor"}
        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
      />
    </Svg>
  </A>
)

export default Facebook
