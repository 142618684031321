import React from "react"
import { motion, useCycle } from "framer-motion"
import Styled from "styled-components"

import MenuToggle from "./Toggle"
import Items from "./Items"

const Menu = Styled(motion.nav)`
  display: none;
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  min-height: ${({ open }) => (open ? "100vh" : "80px")};
  @media screen and (max-width: 600px) {
    display: block;
  }
`
const Background = Styled(motion.div)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    background: ${({ theme }) => theme.primary};
`

const SideBar = {
  open: {
    clipPath: `circle(460px at 260px 15px)`,
    height: "100vh",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  closed: {
    clipPath: "circle(30px at 260px 40px)",
    height: "80px",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
}

const MyMenu = () => {
  const [open, toggleOpen] = useCycle(false, true)

  return (
    <Menu initial={false} animate={open ? "open" : "closed"} open={open}>
      <Background className="background" variants={SideBar} />
      <Items open={open} />
      <MenuToggle toggle={() => toggleOpen()} />
    </Menu>
  )
}

export default MyMenu
