/**
 *! Dynamic Font-Size w/ Clamp
 */
export const GenerateClamp = (
  minWidthPx,
  maxWidthPx,
  minFontSize,
  maxFontSize
) => {
  const root =
    typeof document !== "undefined" ? document.querySelector("html") : null
  const pixelsPerRem = root
    ? Number(getComputedStyle(root).fontSize.slice(0, -2))
    : 1

  const minWidth = minWidthPx / pixelsPerRem
  const maxWidth = maxWidthPx / pixelsPerRem

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minFontSize

  return `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${slope *
    100}vw, ${maxFontSize}rem)`
}
/** ------------------------------------------------------------------
 *! Measurement Helpers
 */
export const cmToInches = cm => {
  return cm * 0.4
}
export const inchesToCm = inches => {
  return `${Math.floor(inches * 2.54)} cm`
}
export const heightToInches = (feet, inches) => {
  return parseInt(feet) * 12 + parseInt(inches)
}
export const inchesToHeight = inches => {
  let feet = parseInt(inches) / 12
  let remainder = parseInt(inches) % 12
  return [feet, remainder]
}
export const cmToHeight = cm => {
  let inches = cmToInches(cm)
  return inchesToHeight(inches)
}
export const heightToCm = (feet, inches) => {
  const i = 12 * parseInt(feet)
  const ii = i + parseInt(inches)
  return `${Math.floor(ii * 2.54)} cm`
}
export const poundsToKg = lbs => {
  return `${Math.floor(lbs / 2.2046226218488)} kgs`
}
export const kgToPounds = kg => {
  return `${Math.ceil(kg * 2.2046226218488)} lbs`
}
/** ------------------------------------------------------------------
 *! Date Helpers
 */
export const isValidDate = (day, month, year) => {
  const date = new Date(year, +month - 1, day)
  return Boolean(+date) && date.getDate() === day
}
const yearInMs = 3.15576e10 // Using a year of 365.25 days (because leap years)
export const getAge = birthDate =>
  Math.floor((new Date() - new Date(birthDate).getTime()) / yearInMs)
const MONTHS = [
  `January`,
  `February`,
  `March`,
  `April`,
  `May`,
  `June`,
  `July`,
  `August`,
  `September`,
  `October`,
  `November`,
  `December`,
]
export const FormatDateM = date => {
  let d = new Date(date)
  let m = d.getMonth()
  return MONTHS[m]
}
export const FormatDateD = (s, e) => {
  let start = new Date(s)
  const ds = start.getDate()
  let end = new Date(e)
  const de = end.getDate()
  return `${ds}-${de}`
}
export const FormatDateY = date => {
  let d = new Date(date)
  return d.getFullYear()
}
const insert = (main, ins, pos) => {
  return main.slice(0, pos) + ins + main.slice(pos)
}
export const WithDecimal = x => {
  if (x === 0) {
    return `0.00`
  }
  return insert(`${x}`, ".", `${x}`.length - 2)
}
export const WithCommas = x => {
  let num_parts = x.toString().split(".")
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  return num_parts.join(".")
}
export const WithDollars = x => {
  return `$${x}`
}
export const FormatCurrenyOutput = x => {
  return WithDollars(WithCommas(WithDecimal(x)))
}
export const CalculatePriceWithDiscount = (cost, percent) => {
  let discount = cost * percent
  return cost - discount
}
/** ------------------------------------------------------------------
 *! String Helpers
 */
export const Truncate = str => {
  return str.length > 23 ? str.substring(0, 23) + "..." : str
}
/** ------------------------------------------------------------------
 *! Object|Array = check if equal
 */
export const IsEqual = (value, other) => {
  const type = Object.prototype.toString.call(value)
  if (type !== Object.prototype.toString.call(other)) {
    return false
  }
  if (["[object Array]", "[object Object]"].indexOf(type) < 0) {
    return false
  }
  const valueLen =
    type === "[object Array]" ? value.length : Object.keys(value).length
  const otherLen =
    type === "[object Array]" ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) {
    return false
  }

  const compare = (item1, item2) => {
    const itemType = Object.prototype.toString.call(item1)

    if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
      // recursive call
      if (!IsEqual(item1, item2)) {
        return false
      }
    } else {
      if (itemType !== Object.prototype.toString.call(item2)) {
        return false
      }

      if (itemType === "[object Function]") {
        if (item1.toString() !== item2.toString()) {
          return false
        }
      } else {
        if (item1 !== item2) {
          return false
        }
      }
    }
  }

  if (type === "[object Array]") {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) {
        return false
      }
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) {
          return false
        }
      }
    }
  }
  return true
}
/** ------------------------------------------------------------------
 *! Local Storage Helpers
 */
export const GetToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("7ea2ceccf2e673357879358b18e3145b")
  }
  return null
}
export const ClearPersist = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("persist:root")
  }
}
export const ClearToken = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("7ea2ceccf2e673357879358b18e3145b")
  }
}
export const SetToken = token => {
  if (typeof window !== "undefined") {
    localStorage.setItem("7ea2ceccf2e673357879358b18e3145b", token)
  }
}
