import { FormatCurrenyOutput } from "@utils"

const TESTING_SERVER = false
const TESTING_STRIPE = true

export const SERVER_URL = TESTING_SERVER
  ? "http://localhost:8080"
  : "https://y3tserver.com"

export const STRIPE = {
  API_KEY: TESTING_STRIPE
    ? `pk_test_FowFglVX00MltWCM46lNu61I00mfzoR4FY`
    : `pk_live_iX5Fdw3lze6MZC51EG2DpRCQ00C0FM17ei`,
}

export const ROUTES = [
  { name: `Home`, path: `/` },
  { name: `FAQs`, path: `/faqs` },
  { name: `Testimonials`, path: `/testimonials` },
  { name: `Register`, path: `/register` },
]

export const SOCIALS = {
  INSTAGRAM: `https://www.instagram.com/neil_yoda_hill1`,
  FACEBOOK: `https://www.facebook.com/neilhilly3t`,
  TWITTER: `https://twitter.com/neil_yoda_hill`,
  YOUTUBE: `https://www.youtube.com/channel/UCxHk8oFNUpNGuTyiUlQiy0g`,
}
//! Coaching Options Divided
export const COACHING_OPTIONS = [
  { value: { label: "Lean Mass", key: "lean_mass" }, label: "Lean Mass" },
  {
    value: { label: "Transformation", key: "transformation" },
    label: "Transformation",
  },
  { value: { label: "Pre-Contest", key: "pre_contest" }, label: "Pre-Contest" },
  {
    value: { label: "One-Off Coaching", key: "one_off", cost: 50000 },
    label: "One-Off Coaching - $500.00",
  },
]
export const COACHING_DURATION = {
  lean_mass: [
    { value: { label: "12 Weeks", key: "twelve_weeks" }, label: "12 Weeks" },
    { value: { label: "6 Months", key: "six_months" }, label: "6 Months" },
  ],
  transformation: [
    { value: { label: "12 Weeks", key: "twelve_weeks" }, label: "12 Weeks" },
    { value: { label: "6 Months", key: "six_months" }, label: "6 Months" },
  ],
  pre_contest: [
    { value: { label: "12 Weeks", key: "twelve_weeks" }, label: "12 Weeks" },
  ],
}
export const COACHING_LEVELS = {
  lean_mass: {
    twelve_weeks: [
      {
        value: { label: "Bronze", cost: 121000, key: "bronze" },
        label: `Bronze - ${FormatCurrenyOutput(121000)}`,
      },
      {
        value: { label: "Silver", cost: 148500, key: "silver" },
        label: `Silver - ${FormatCurrenyOutput(148500)}`,
      },
      {
        value: { label: "Gold", cost: 176000, key: "gold" },
        label: `Gold - ${FormatCurrenyOutput(176000)}`,
      },
    ],
    six_months: [
      {
        value: { label: "Bronze", cost: 242000, key: "bronze" },
        label: `Bronze - ${FormatCurrenyOutput(242000)}`,
      },
      {
        value: { label: "Silver", cost: 297000, key: "silver" },
        label: `Silver - ${FormatCurrenyOutput(297000)}`,
      },
      {
        value: { label: "Gold", cost: 352000, key: "gold" },
        label: `Gold - ${FormatCurrenyOutput(352000)}`,
      },
    ],
  },
  transformation: {
    twelve_weeks: [
      {
        value: { label: "Bronze", cost: 137500, key: "bronze" },
        label: `Bronze - ${FormatCurrenyOutput(137500)}`,
      },
      {
        value: { label: "Silver", cost: 165000, key: "silver" },
        label: `Silver - ${FormatCurrenyOutput(165000)}`,
      },
      {
        value: { label: "Gold", cost: 192500, key: "gold" },
        label: `Gold - ${FormatCurrenyOutput(192500)}`,
      },
    ],
    six_months: [
      {
        value: { label: "Bronze", cost: 275000, key: "bronze" },
        label: `Bronze - ${FormatCurrenyOutput(275000)}`,
      },
      {
        value: { label: "Silver", cost: 330000, key: "silver" },
        label: `Silver - ${FormatCurrenyOutput(330000)}`,
      },
      {
        value: { label: "Gold", cost: 385000, key: "gold" },
        label: `Gold - ${FormatCurrenyOutput(385000)}`,
      },
    ],
  },
  pre_contest: {
    twelve_weeks: [
      {
        value: { label: "Bronze", cost: 145800, key: "bronze" },
        label: `Bronze - ${FormatCurrenyOutput(145800)}`,
      },
      {
        value: { label: "Silver", cost: 173300, key: "silver" },
        label: `Silver - ${FormatCurrenyOutput(173300)}`,
      },
      {
        value: { label: "Gold", cost: 200800, key: "gold" },
        label: `Gold - ${FormatCurrenyOutput(200800)}`,
      },
      {
        value: { label: "Platinum", cost: 228300, key: "platinum" },
        label: `Platinum - ${FormatCurrenyOutput(228300)}`,
      },
    ],
  },
}
const GenerateExtraWeeks = cost => {
  const weeks = []
  for (let i = 0; i < 11; i++) {
    if (i === 1) {
      weeks.push({
        value: { weeks: i, cost: cost },
        label: `Add ${i} week(s) - ${FormatCurrenyOutput(cost)}`,
      })
    } else {
      weeks.push({
        value: { weeks: i, cost: i * cost },
        label: `Add ${i} week(s) - ${FormatCurrenyOutput(i * cost)}`,
      })
    }
  }
  return weeks
}
export const COACHING_EXTRA_WEEKS = {
  pre_contest: {
    bronze: GenerateExtraWeeks(11000),
    silver: GenerateExtraWeeks(13100),
    gold: GenerateExtraWeeks(15200),
    platinum: GenerateExtraWeeks(17300),
  },
}
export const COACHING_PAYMENTS_TYPES = {
  lean_mass: [
    {
      value: { label: "Full Payment Upfront (save 10%)", discount: 0.1 },
      label: "Full Payment Upfront (save 10%)",
    },
    {
      value: { label: "Payment Plan", discount: false },
      label: "Payment Plan",
    },
  ],
  transformation: [
    {
      value: { label: "Full Payment Upfront (save 10%)", discount: 0.1 },
      label: "Full Payment Upfront (save 10%)",
    },
    {
      value: { label: "Payment Plan", discount: false },
      label: "Payment Plan",
    },
  ],
  pre_contest: [
    {
      value: { label: "Full Payment Upfront (save 10%)", discount: 0.1 },
      label: "Full Payment Upfront (save 10%)",
    },
    {
      value: { label: "Payment Plan", discount: 0 },
      label: "Payment Plan",
    },
  ],
  one_off: [
    {
      value: { label: "Full Payment", discount: 0 },
      label: "Full Payment",
    },
    {
      value: { label: "Payment Plan", discount: 0 },
      label: "Payment Plan",
    },
  ],
}

export const COACHING_PAYMENTS = [
  { value: { label: "60% Upfront", split: 0.4 }, label: "60% Upfront" },
  {
    value: { label: "40% 6 Weeks Later", split: 0.6 },
    label: "40% 6 Weeks Later",
  },
]

//! Current Use
export const COACHING_OPTIONS_V2 = {
  lean_mass: {
    label: `Lean Mass`,
    duration: [
      {
        label: `12 Weeks`,
        levels: [
          {
            label: `Bronze`,
            cost: 121000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Silver`,
            cost: 148500,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Gold`,
            cost: 176000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
        ],
      },
      {
        label: `6 Months`,
        levels: [
          {
            label: `Bronze`,
            cost: 242000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Silver`,
            cost: 297000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Gold`,
            cost: 352000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  transformation: {
    label: `Transformation`,
    duration: [
      {
        label: `12 Weeks`,
        levels: [
          {
            label: `Bronze`,
            cost: 137500,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Silver`,
            cost: 165000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Gold`,
            cost: 192500,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
        ],
      },
      {
        label: `6 Months`,
        levels: [
          {
            label: `Bronze`,
            cost: 275000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Silver`,
            cost: 330000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Gold`,
            cost: 385000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  pre_contest: {
    label: `Pre-Contest`,
    duration: [
      {
        label: `12 Weeks`,
        levels: [
          {
            label: `Bronze`,
            cost: 145800,
            extra_weeks: 11000,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Silver`,
            cost: 173300,
            extra_weeks: 13100,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Gold`,
            cost: 200800,
            extra_weeks: 15200,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.4 },
                  { label: `40% 6 Weeks Later`, split: 0.6 },
                ],
              },
            ],
          },
          {
            label: `Platinum`,
            cost: 228300,
            extra_weeks: 17300,
            payments: [
              { label: `Full Payment Upfront (save 10%)`, discount: 0.1 },
              {
                label: `Payment Plan`,
                discount: false,
                payments: [
                  { label: `60% Upfront`, split: 0.6 },
                  { label: `40% 6 Weeks Later`, split: 0.4 },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  one_off: {
    label: `One-Off Coaching`,
    cost: 50000,
    payments: [
      { label: `Pay In Full`, discount: 0.0 },
      {
        label: `Payment Plan`,
        discount: false,
        payments: [
          { label: `60% Upfront`, split: 0.4 },
          { label: `40% 6 Weeks Later`, split: 0.6 },
        ],
      },
    ],
  },
}

export const PROGRAM_LOOKUP = {
  lean_mass: "Lean Mass Coaching",
  transformation: "Transformation Coaching",
  pre_contest: "Pre-Contest Coaching",
  one_off: "One-Off Coaching",
}
