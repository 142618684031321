import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import Styled from "styled-components"

const Route = Styled(Link)`
    color: ${({ theme }) => theme.black};
    border-radius: 5px;
    width: 200px;
    height: 30px;
    flex: 1;
    font-size: 35px;
    text-align: right;
    justify-content: flex-end;
    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.white};
    }
    display: ${({ open }) => (!open ? "none" : "flex")};
`
const RouteBtn = Styled.button`
/* reset */
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  /* btn styles */
  color: ${({ theme }) => theme.black};
  border-radius: 5px;
  width: 200px;
  height: 30px;
  flex: 1;
  font-size: 35px;
  text-align: right;
  justify-content: flex-end;
  &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.white};
  }
  display: ${({ open }) => (!open ? "none" : "flex")};
`
const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const Item = ({ path, name, open, onClick = null }) => {
  if (onClick) {
    return (
      <motion.li
        variants={variants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        <RouteBtn onClick={onClick} open={open}>
          {name}
        </RouteBtn>
      </motion.li>
    )
  }
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <Route to={path} open={open}>
        {name}
      </Route>
    </motion.li>
  )
}

export default Item
