import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Styled from "styled-components"
import { connect } from "react-redux"
import * as actions from "@redux/actions"

import Menu from "../Menu"

import { ROUTES } from "../../config"

const Nav = Styled.nav`
  padding: 0;
  margin: 0px 9px 0px 9px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Logo = Styled(Img)`
	cursor: pointer;
	width: 80px;
	&:hover {
		opacity: 0.6;
	}
`
const UL = Styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	@media screen and (max-width: 600px) {
		${({ isLogo }) => (isLogo ? null : "display: none")};
	}
`
const Route = Styled(Link)`
	font-family: ${({ theme }) => theme.robotoFont};
	color: ${({ theme }) => theme.white};
	text-transform: uppercase;
	font-weight: 600;
	padding: 22px 9px 24px 9px;
	font-size: 0.9em;
	transition: all 0.2s linear;
	&:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.primary};
	}
`
const RouteBtn = Styled.button`
  /* reset */
  background: none;
	color: inherit;
	border: none;
	padding: 0;
  margin: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  /* btn styles */
  font-family: ${({ theme }) => theme.robotoFont};
	color: ${({ theme }) => theme.white};
	text-transform: uppercase;
	font-weight: 600;
	padding: 22px 9px 19px 9px;
	font-size: 0.9em;
	transition: all 0.2s linear;
	&:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.primary};
	}
`

const MyNav = ({ authenticated, ReduxLogout }) => {
  //! Static Query
  const data = useStaticQuery(graphql`
    query navQuery {
      logo: allFile(filter: { name: { eq: "logo_600x262" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)
  const logo = data.logo.edges[0].node.childImageSharp.fluid
  return (
    <Nav>
      <UL isLogo>
        <li>
          <Route to="/">
            <Logo
              fluid={logo}
              alt={`Y3T`}
              imgStyle={{ objectFit: "contain" }}
            />
          </Route>
        </li>
      </UL>
      <UL>
        {ROUTES.map(route => (
          <li key={`nav-${route.name}`}>
            <Route to={route.path}>{route.name}</Route>
          </li>
        ))}
        {authenticated ? (
          <>
            <li>
              <Route to="/app/profile">Profile</Route>
            </li>
            <li>
              <RouteBtn onClick={() => ReduxLogout()}>Logout</RouteBtn>
            </li>
          </>
        ) : (
          <li>
            <Route to="/app/login">Login</Route>
          </li>
        )}
      </UL>
      <Menu />
    </Nav>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
  }
}
export default connect(mapStateToProps, actions)(MyNav)
